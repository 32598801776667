.chat {
  z-index: +999999999999;
  position: relative;
  display: block;
  &-circle {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: $secondary;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    transition: 0.3s ease all;
    cursor: pointer;
    &:hover {
      background: $primary;
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.5rem;
      transform: translate(-50%, -50%);
    }
  }
  &-window {
    position: fixed;
    overflow: auto;
    bottom: 6rem;
    right: 2rem;
    width: calc(100% - 4rem);
    height: calc(100% - 12rem);
    max-width: 30rem;
    max-height: 60rem;
  }
}
