.journal {
  &_readtime {
  }
  &_category {
  }
  &_title {
    @include media-breakpoint-up(lg) {
      line-height: 1.1;
    }
    @include media-breakpoint-down(md) {
      font-size: 2.75rem;
    }
  }
  &_date {
    font-family: $headings-font-family;
    font-size: 1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
    font-weight: 200;
  }
  &_authortop {
    margin-top: 3rem;
    .gatsby-image-wrapper {
      max-width: 5rem;
      margin: auto;
      margin-bottom: 1rem;
    }
  }

  &_content {
    section:not(.sticky-top) {
      position: relative;
      z-index: 2;
    }

    &__listicle {
      z-index: 0 !important;
    }

    h2,
    h3,
    h4,
    h5 {
      text-align: center;
    }

    .oldcontent {
      h2,
      h3,
      h4,
      h5 {
        text-align: left;
      }

      h2{
        margin-top: 3rem;
        font-size: 2.25rem;
        @include media-breakpoint-up(lg) {
          font-size: 2.5rem;
          margin-top: 4rem;
        }
        margin-bottom: 1rem;
      }
      h3{
        margin-bottom: 0.5rem;
        margin-top: 1.75rem;
        font-size: 1.33rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
          margin-top: 2.25rem;
        }
      }
      h4{
        margin-bottom: 0.5rem;
        font-size: 1.15rem;
        margin-top: 1.5rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.25rem;
          margin-top: 1.25rem;
        }
      }
      h5{
        font-size: 1.1rem;
        margin-top: 1.25rem;
        margin-bottom: 0.5rem;
      }

      // @include media-breakpoint-up(lg) {
      //   .gatsby-image-wrapper:first-of-type {
      //     width: 130% !important;
      //     max-width: 130% !important;
      //     margin-left: -15%;
      //     margin-bottom: 3rem !important;
      //   }
      // }
    }

    &__sidebar {
      z-index: 3 !important;
      top: 5rem !important;

      &_inner {
        position: absolute;
        padding: 5rem 1rem 5rem 0;
        right: 90%;
        width: 20%;

        z-index: -1;
      }
    }

    &__pulllink {
      padding: 2rem;
      margin-top: 2rem;
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 100%;
        bottom: 3rem;
        width: 30%;
      }

      .heading {
      }
      h5 {
        margin-bottom: 1rem;
      }
    }
    &__partof {
      padding: 7rem 0 0rem 0;
      hr {
        margin-bottom: -5rem;
        border-width: 0.2rem;
      }
      &_title {
        padding: 2rem;
        background: $white;
      }
    }

    &:last-child {
      @extend .pb-5;
    }
  }
}

.card {
  &-journal {
    &-large {
      h2 {
        font-size: 1.25rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.75rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 2.25rem;
        }
      }
      .lead-lg {
        font-size: 1.15rem;
        @include media-breakpoint-up(xl) {
          font-size: 1.25rem;
        }
      }
      @include media-breakpoint-up(lg) {
        a,
        .gatsby-image-wrapper {
          display: block;
          // height: 100% !important;
        }
        img {
          object-fit: cover;
        }
      }
    }

    .gatsby-image-wrapper {
      img {
        transition: 2s ease all !important;
      }
    }
    &:hover {
      .gatsby-image-wrapper {
        img {
          transform: translateY(-2.5%) scale(1.05);
        }
      }
    }
  }
}
