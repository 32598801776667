@mixin get-color-yiq($background, $attribute:"color") {
    $r: red($background);
    $g: green($background);
    $b: blue($background);
  
    $yiq: ((($r * 299) + ($g * 587) + ($b * 114))/1000);
  
    @if ($yiq >= $yiq-contrasted-threshold) {
     #{$attribute}: $yiq-text-dark;
    } @else {
     #{$attribute}: $yiq-text-light;
    }
  }
  