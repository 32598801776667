@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  #hero.clipped {
    max-height: 55vh;
    min-height: 45rem;
    clip-path: none;
    overflow: hidden;
    padding-bottom: 0 !important;
  }

  #partners {
    padding-top: 2rem !important;
    margin-top: 0 !important;
  }

  .floaty {
    transform: none !important;
  }

  .hubspot_form #form {
    margin-top: -15rem !important;
  }

  .pricing_background {
    position: relative !important;
  }

  #featureslist {
    display: none !important;
  }

  .featurelist-content-item .d-block.d-md-none {
    display: block !important;
    text-align: center;
  }

  #success .gatsby-image-wrapper{
      max-height: none !important;
  }
}
