
nav {
  z-index: +99999;
  display: block;
  position: relative;
  // &.navbar-dark{
  // margin-bottom: -7rem;
  // }
  transition: 0.2s ease all;
  &#mainnavbar {

      max-width: 2200px !important;
      margin-left: auto;
      margin-right: auto;

    .navbar {
      @include media-breakpoint-up(lg) {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
        margin: auto;
      }
      #phNavbar a.btn {
        @include media-breakpoint-down(md) {
          font-size: 0.8rem !important;
          max-width: 400px;
          padding: 0.25rem !important;
          margin: 0.75rem auto !important;
        }
      }
      #phNavbar a:not(.btn) {
        @include media-breakpoint-down(md) {
          color: $white !important;
          font-size: 1.15rem !important;
          line-height: 1.5rem !important;
        }
        &.active {
          color: $secondary !important;
        }

        // font-family: $headings-font-family;
        // font-weight: $font-weight-bold;
        // text-transform: uppercase;
        @include media-breakpoint-up(xl) {
          padding: 0.5rem 1.33rem;
        }
        @include media-breakpoint-up(xxl) {
          padding: 0.5rem 1.75rem;
        }
      }
    }
  }
}
body {
  @include media-breakpoint-up(md) {
    overflow-x: hidden;
    position: relative;
  }
}
#___gatsby {
  @include media-breakpoint-down(md) {
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: 100vh;
    position: relative;
  }
  @include media-breakpoint-down(md) {
    // .aos-animate {
    //   opacity: 1 !important;
    //   transform: none !important;
    //   transition: none !important;
    // }
  }
}

.with-alert {
  margin-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    // margin-bottom: 0rem;
  }
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: +9999;
  transition: 0.3s ease all;
  height: 4rem;
  @include media-breakpoint-up(lg) {
    height: 4.75rem;
  }

  .headeralert {
    margin: 0 -15px;
    transition: 0.3s ease all;
    &-text {
      font-size: 0.8rem;
    }
  }

  figure {
    margin-bottom: 0;
  }
  .brand,
  .brand-icon {
    transition: 0.3s ease all;

    position: absolute;
    left: 1rem;
    top: 0;
    &.light {
      opacity: 0;
    }
  }
  &.scrolled {
    .navbar-toggler.navbarlight {
      .bar1,
      .bar2,
      .bar3 {
        background-color: $white !important;
      }
    }
    a {
      color: $navbar-dark-color !important;
      &:hover {
        color: $navbar-dark-hover-color !important;
      }
    }

    background: $primary;
    .navbar-expand-lg {
      background: $primary;
      .navbar {
        // @include media-breakpoint-up(md) {
        //   padding: 0 !important;
        // }
      }
      .brand {
        height: 2.25rem;
      }
    }
    &.with-alert {
      .headeralert {
        margin-top: -4rem;
      }
    }
    .brand {
      &.light {
        opacity: 1;
      }
      // &.dark {
      //   display: none;
      // }
    }
  }
}

#features {
  position: relative;
  // &:before{
  //   content: '';
  //   background: $light;
  //   height: 50%;
  //   max-height: 50vh;
  //   width: 100%;
  //   top:0;
  //   position: absolute;
  // }

  .featurelist-content {
    @include media-breakpoint-up(md) {
      &-item {
        opacity: 0;
        transition: 1s ease all;
        &.active {
          opacity: 1;
        }

        .gatsby-image-wrapper {
          max-width: 45vw;
          margin: auto;
        }
      }
    }
  }
  #featureslist {
    z-index: 0;
    @include media-breakpoint-up(md) {
      .sticky-top:after {
        content: "";
        position: absolute;
        height: 50%;
        top: 49vh;
        left: -50%;
        width: 100vw;
        background: $white;
        z-index: -1;
      }
    }

    @media only screen and (max-height: 880px),
      screen and (max-width: 800px) and (max-height: 1200px) {
      .nav-item {
        display: block;
        .active p {
          opacity: 1;
          height: auto;
        }
        p {
          opacity: 0;
          height: 0;
          transition: 0.3s ease all;
        }
      }
    }
  }
  nav {
    min-height: 100vh;
    .nav-item {
      display: block;
      clear: both;
      .nav-link {
        @extend .mr-lg-3;
        transition: 0.2s ease all;
        opacity: 0.5;
        position: relative;

        h2 {
          z-index: 1;
          &:before {
            content: "";
            background: linear-gradient(
              to right,
              rgba($primary, 0) 0%,
              rgba($primary, 1) 25%,
              rgba($primary, 0) 100%
            );
            opacity: 0.2;
            width: 0%;
            height: 0.5rem;
            position: absolute;
            left: 30%;
            top: 1.5rem;
            z-index: 0;
            transition: 0.7s ease all;
          }
        }
        &.active {
          opacity: 1;
          h2 {
            &:before {
              width: 30vw;
            }
          }
        }
        color: $primary;
      }
    }
  }
}

.sticky-top {
  flex: none;
}

.navbar-toggler {
  z-index: +999999;
  &:focus {
    border: 0;
    outline: 0;
  }

  .bar1,
  .bar2,
  .bar3 {
    pointer-events: none;
    width: 2rem;
    height: 2px;
    background-color: $white !important;
    margin: 6px 0;
    transition: 0.3s ease all;
  }

  &.navbarlight {
    .bar1,
    .bar2,
    .bar3 {
      background-color: $primary !important;
    }
    &.show {
      .bar1,
      .bar2,
      .bar3 {
        background-color: $white !important;
      }
    }
  }

  /* Rotate first bar */
  .bar1 {
    margin-top: 0;
    transform: rotate(-45deg) translate(-8px, 6px);
  }

  /* Fade out the second bar */
  .bar2 {
    opacity: 0;
    transform: translateX(200px);
  }

  /* Rotate last bar */
  .bar3 {
    transform: rotate(45deg) translate(-5px, -4px);
  }

  &:not(.show) {
    .bar1,
    .bar2,
    .bar3 {
      transform: none;
    }
    .bar2 {
      left: 0;
      opacity: 1;
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-collapse {
    height: 100vh !important;
    position: fixed;
    width: 100%;
    left: 0;
    padding: 2rem 2rem 3rem 2rem;
    background: $primary;
    // background-image: linear-gradient(135deg, #502246 0%, #1F1A3C 100%);
    background-size: 200% 100%;
    top: -100vh;
    transition: 0.5s ease-out all;
    display: block !important;
    z-index: 0;
    // padding-top: 5rem;

    a {
      position: relative;
      // right: -10rem;
      opacity: 0;
      transition: 0.5s ease all;
    }

    &.collapse {
      clip-path: circle(20% at 50% 50%);
    }
    &.collapsing,
    &.show {
      background-position: center 100%;
      clip-path: circle(90% at 50% 50%);

      a {
        right: 0rem;
        opacity: 1;
      }
    }
    // &.show{
    //   clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    // }

    a.text-primary,
    a.text-white {
      color: $white !important;
      font-size: 1.75rem;
      margin-right: 0 !important;
      margin-bottom: 1rem;
      font-weight: $font-weight-bold;
    }
    a.btn {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      color: $white !important;
      &:hover {
        color: $white !important;
      }
      font-size: 1.5rem;
      display: block;
      margin-right: 0 !important;
      font-family: $headings-font-family;
      font-weight: $font-weight-bold;
    }

    &.collapsing,
    &.show {
      top: 3.6rem;
    }
  }
}

.navbar-light .navbar-nav {
  .nav-link:hover,
  nav-link:focus {
    &.btn {
      color: $white !important;
    }
  }
}

@include media-breakpoint-down(md) {
  a.dropdown-toggle {
    display: none !important;
  }
  .navbar-nav .dropdown-menu {
    display: block !important;
    // text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  ul.navbar-nav li.dropdown:hover {
    background: $primary;
    border-radius: 0.5rem 0.5rem 0 0;
    a.dropdown-toggle {
      color: $secondary !important;
    }
    .dropdown-menu {
      display: block;
    }
  }
  ul.navbar-nav {
    .dropdown {
      a.dropdown-toggle {
        padding-right: 2rem !important;
        &:after {
          position: absolute;
          right: 1rem;
          top: 0.75rem;
          content: "\f107";
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
        }
      }
    }
    .dropdown-menu {
      opacity: 1;

      border-radius: 0rem 2rem 0.5rem 2rem;
      padding: 0.66rem 0.5rem 1.25rem 0.5rem;
      margin-top: 0;
      // &::before {
      //   content: '';
      //   border-bottom: 10px solid $primary;
      //   border-right: 10px solid transparent;
      //   border-left: 10px solid transparent;
      //   position: absolute;
      //   top: -10px;
      //   left: 40%;
      //   z-index: 10;
      // }
      background: $primary;
      a {
        color: $white !important;
        padding: 0.5rem 1.222rem !important;
        &:hover {
          color: $success !important;
        }
      }
    }
  }
}
.dropdown-menu {
  opacity: 1;
  @include media-breakpoint-down(md) {
    box-shadow: unset !important;
  }
}

.navbar-toggler {
  cursor: pointer;
}
.scrolled {
  .navbar-toggler {
    span {
      background-color: $white !important;
    }
  }
}

.brand,
.brand-icon {
  height: 4rem;
  transition: 0.3s ease all;

  @include media-breakpoint-up(lg) {
    height: 4.75rem;
  }
}
